.footer-bg{ background-image: url(../images/footer.png); /*position: fixed;bottom: 0px; */ width: 100%;background-repeat: no-repeat;background-position: center top; background-size: cover; padding: 46px 0px 10px 0px; text-align: center; font-size: 14px;;}
.FooterArrow{position: absolute; left: 50%; top: -6px;;}
.relative-1{position: relative;}
.footerlinks{ margin: 12px 0px;}
.footerlinks ul{margin: 0px; padding: 0px; list-style: none;;}
.footerlinks ul li{margin: 0px 20px; padding: 0px; list-style: none; display: inline-block; }
.footerlinks ul li a{ color: #000; text-decoration: none;}

.rotate {
    transition: transform 0.3s ease;
  }
  
  .rotated {
    transform: rotate(180deg);
  }
  
  .cursor-pointer {
    cursor: pointer;
  }


  
.modal-overlay-footer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content-footer {
  background: white;
  padding: 20px;
  width: 90%;
  max-width: 800px;
  height: 550px;
  border-radius: 8px;
  position: relative;
  overflow-y: auto;
}

.close-button-footer {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.link-button {
  background: none;
  border: none;
  color: black;
  cursor: pointer;
  /* text-decoration: underline; */
  font-size: 16px;
}

.terms-content {
  max-height: 480px;
  overflow-y: auto;
  padding: 10px;
  background-color: #fff;
  /* background-image: url(./components/images/new-bg.png); */
  /* background-image: url(../images/new-bg.png); */
  background-size: 100% 100%;
  /* background-attachment: fixed; */
  
}


@media screen and (max-width : 600px){
  .modal-content-footer {
    padding: 10px;
  }
}