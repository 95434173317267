.maintenance-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    /* background-color: #f8f9fa; */
  }
  
  .logo {
    width: 40%;
    height: auto;
    margin-bottom: 20px;
  }
  
  h1 {
    color: #333;
    font-size: 28px;
    text-align: center;
  }

  
  p {
    color: #666;
    font-size: 18px;
  }
  